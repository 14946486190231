import React from "react";
import Head from "next/head";

const RenderSEOTag = (pageData) => {
    
  const pageKey = Object.keys(pageData);
  const componentData = (pageKey.length >= 4 ? pageData[3] : false);

  const seoData = Array.isArray(componentData.data) ? componentData.data[0].attributes.SEO : componentData.data.attributes.SEO;
  if(!seoData){
    return null;
  }

  const seoDataKey = Object.keys(seoData);

  let validObj = {
    'metaDescription' : 'description',
    'keywords' : 'keywords',
  };
  let validKey = Object.keys(validObj);

  let output = [];
  seoDataKey.forEach(key => {
    let content = seoData[key];
    if(key=='metaTitle'){
      output.push(<title key={key}>{content}</title>);
    }else if(content && validKey.indexOf(key) >= 0){
      let properKey = validObj[key];
      output.push(<meta key={key} name={properKey} content={content} />)
    }else if(key == 'metaSocial'){
      content.forEach(sArr => {
        let socialkey = ['title', 'description', 'imageLink'];
        let prefix = (sArr['socialNetwork'] == 'Twitter' ? 'twitter' : 'og') + ':';
        socialkey.forEach(sKey => {
          let sContent = sArr[sKey];
          let properKey = prefix + (sKey == 'imageLink' ? 'image' : sKey);
          output.push(<meta key={sKey} name={properKey} content={sContent} />)
        })
      })
    }else if(key == 'canonicalURL'){
      output.push(<link key={key} rel="canonical" href={content} />);
    }
  });

  return (
    <Head>
      {output}
    </Head>
  );
}

export default RenderSEOTag;