import React, { getGlobal } from 'reactn';
import { useRouter } from 'next/router';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const Footer = (pageData) => {
  const pageKey = Object.keys(pageData);
  const footerdata = pageKey.length >= 2 ? pageData[1] : false;
  const translate = pageKey.length >= 3 ? pageData[2].data.attributes : false;

  // Module Contactus
  const Contactus = ({ title, link, contactUs }) => {
    return (
      <div>
        <Container className={`contact`}>
          <Row className={`align-items-center pb-5`}>
            <Col xs={12} md>
              <h2
                className={`title mb-4 mb-md-0 px-5 px-md-0 text-center text-md-start`}
              >
                {title}
              </h2>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={3}
              className="d-flex d-lg-none justify-content-center justify-content-md-end"
            >
              <a
                className={`btn btn-green py-4`}
                href={`http://boutir.com/app`}
                target={`_blank`}
                data-section="contact-us"
              >
                {translate.StartTrial}
              </a>
            </Col>
            <Col
              xs={12}
              md={3}
              lg={3}
              className="d-none d-lg-flex justify-content-center justify-content-md-end"
            >
              <a
                className={`btn btn-green py-4`}
                href={link}
                target={`_blank`}
                data-section="contact-us"
              >
                {translate.StartTrial}
              </a>
            </Col>
            <Col xs={12}>
              <div className={`border-line py-4`}></div>
            </Col>
          </Row>
          <Row>
            <Col xs={12}>
              <h3 className={`subtitle mb-3 mb-md-0 text-center text-md-start`}>
                {contactUs.Title}
              </h3>
            </Col>
            <Col xs={12} md className={`text-center pt-2 pt-md-3`}>
              <a
                className={`btn mt-0 mt-md-3 py-3 w-100`}
                id={`talk-to-us`}
                href={contactUs.TalkToUsLink}
                target={`_blank`}
              >
                <div className={`mb-3`}>{contactUs.TalkToUs}</div>
              </a>
            </Col>
            <Col xs={12} md className={`text-center pt-2 pt-md-3`}>
              <a
                className={`btn mt-0 mt-md-3 py-3 w-100`}
                id={`free-consultation`}
                href={contactUs.FreeConsultationLink}
                target={`_blank`}
              >
                <div className={`mb-3`}>{contactUs.FreeConsultation}</div>
              </a>
            </Col>
            <Col xs={12} md className={`text-center pt-2 pt-md-3`}>
              <a
                className={`btn mt-0 mt-md-3 py-3 w-100`}
                id={`join-webinars`}
                href={contactUs.JoinWebinarsLink}
                target={`_blank`}
              >
                <div className={`mb-3`}>{contactUs.JoinWebinars}</div>
              </a>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  const Foot = ({ data, navList }) => {
    return (
      <div>
        <Container className={`info py-4`}>
          <div
            className={`d-flex py-4 justify-content-between align-items-end align-items-md-center`}
          >
            <div className={`logo`}>
              <LazyLoadImage
                src={data.BrandLogo}
                alt={data.BrandLogoAlt}
                effect={`blur`}
                width={`114`}
                height={`47`}
              />
            </div>
            <div className={`apps d-flex`}>
              <a
                className={`mx-1`}
                href={data.GooglePlayLink}
                target={`_blank`}
              >
                <LazyLoadImage
                  src={data.GooglePlayLogo}
                  alt={data.GooglePlayLogoAlt}
                  effect={`blur`}
                  width={`108`}
                  height={`32`}
                />
              </a>
              <a className={`mx-1`} href={data.AppStoreLink} target={`_blank`}>
                <LazyLoadImage
                  src={data.AppStoreLogo}
                  alt={data.AppStoreLogoAlt}
                  effect={`blur`}
                  width={`108`}
                  height={`32`}
                />
              </a>
            </div>
          </div>
          <Row>
            <Col xs={12} lg={7}>
              <Row className={`justify-center-between`}>
                {navList.map((nav, index) => {
                  return (
                    <React.Fragment key={`${index}`}>
                      {nav.Hidden === false ? (
                        <Col xs={6} md>
                          <div className={`label mt-4 mt-md-0 mb-3`}>
                            {nav.Label}
                          </div>
                          {nav.NavItem.map((i, key) => {
                            return (
                              <React.Fragment key={`${key}`}>
                                {i.Hidden === false ? (
                                  <div key={`${key}`} className={`item mb-2`}>
                                    <a
                                      href={
                                        i.NavLink &&
                                        i.NavLink.indexOf('http') == -1
                                          ? linkprefix + '/' + i.NavLink
                                          : i.NavLink
                                      }
                                      target={`${
                                        i.NewTab == true ? '_blank' : ''
                                      }`}
                                    >
                                      {i.NavText}
                                    </a>
                                  </div>
                                ) : (
                                  <div></div>
                                )}
                              </React.Fragment>
                            );
                          })}
                        </Col>
                      ) : (
                        ''
                      )}
                    </React.Fragment>
                  );
                })}
              </Row>
            </Col>
            <Col xs={12} lg={{ span: 4, offset: 1 }} className={`contactus`}>
              <div className={`label mt-4 mt-md-0 mb-3`}>
                {translate.ContactUs}
              </div>
              <Row>
                <Col xs={6} lg={12}>
                  <a href={`mailto:${data.ContactEmail}`}>
                    <div className={`mail item d-flex align-items-center mb-2`}>
                      {data.ContactEmail}
                    </div>
                  </a>
                </Col>
                <Col xs={6} lg={12}>
                  <a href={data.ContactFBLink}>
                    <div
                      className={`fbmessager item d-flex align-items-center mb-2`}
                    >
                      {data.ContactFBText}
                    </div>
                  </a>
                </Col>
                <Col xs={6} lg={12}>
                  <a href={data.ContactWhatsappLink}>
                    <div
                      className={`whatsapp item d-flex align-items-center mb-2`}
                    >
                      {data.ContactWhatsappText}
                    </div>
                  </a>
                </Col>
              </Row>
              <div className={`label mt-4 mb-3`}>{translate.ServiceHour}</div>
              <Row className={`hour`}>
                <Col xs={6} md={4} className={`item`}>
                  <span>{translate.Weekday}</span>
                  <br />
                  <span>{data.ServiceHourWeekday}</span>
                  <div className={`d-flex align-items-center mt-5 mt-md-3`}>
                    <a
                      href={data.SocialFBLink}
                      target={`_blank`}
                      rel={`noreferrer`}
                    >
                      <div className={`fb-social`}></div>
                    </a>
                    <a
                      href={data.SocialIGLink}
                      target={`_blank`}
                      rel={`noreferrer`}
                    >
                      <div className={`ig-social mx-3`}></div>
                    </a>
                    <a
                      href={data.SocialYTLink}
                      target={`_blank`}
                      rel={`noreferrer`}
                    >
                      <div className={`yt-social`}></div>
                    </a>
                  </div>
                </Col>
                <Col xs={6} md className={`item`}>
                  <span>{translate.Weekend}</span>
                  <br />
                  <span>{data.ServiceHourWeekend}</span>
                  <div className={`d-block d-md-none mt-4`}>
                    <div className={`copyright pt-2`}>
                      Copyright © {new Date().getFullYear()} BOUTIR LIMITED.
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12}>
              <div className={`copyright d-none d-md-block`}>
                Copyright © {new Date().getFullYear()} BOUTIR LIMITED.
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  };

  let ftData = '';
  if (footerdata.data !== undefined) {
    ftData = footerdata.data.attributes;
  } else {
    return null;
  }

  const router = useRouter();
  const { asPath } = router;
  let component =
    asPath.split('/').length > 4 ? asPath.split('/')[3] : 'homepage';

  let { country, lang } = getGlobal().linkstate;
  let linkprefix = `/${country}/${lang}`;
  return (
    <footer>
      <Contactus
        title={ftData.StartFreeTrail}
        link={ftData.StartFreeTrailLink}
        contactUs={ftData.FooterContactUs}
      />
      <Foot data={ftData} navList={ftData.Nav} />
    </footer>
  );
};

export default Footer;
