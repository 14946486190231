import React, { useState, getGlobal } from 'reactn';
import Cookies from 'universal-cookie';
import { useSignUpLink, useLoginLink } from '../hooks/useBoutirAdmin';

import { useRouter } from 'next/router';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Modal from 'react-bootstrap/Modal';

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const setCookies = (region, lang) => {
  const cookies = new Cookies();

  cookies.set('region', region, { path: '/' });
  cookies.set('lang', lang, { path: '/' });
};

const DropdownLinkItem = ({ item, component, linkprefix, setExpanded }) => {
  return (
    <DropdownButton
      size="large"
      variant="default"
      renderMenuOnMount={true}
      title={item.NavText}
      id={`${item.Identity}Dropdown`}
      className={component === item.Identity ? ' active' : ''}
    >
      <Container className={`py-1 py-lg-5`}>
        <Row>
          <Col xs={12} lg={{ span: 10, offset: 1 }}>
            <Row>
              {item.Dropdown?.map(
                (dropdown) =>
                  !dropdown.Hidden && (
                    <Col
                      key={`${dropdown.id}`}
                      xs={12}
                      lg={6}
                      className={`py-2 px-0 px-lg-3`}
                    >
                      <Dropdown.Item
                        href={
                          dropdown.Link && dropdown.Link.indexOf('http') == -1
                            ? linkprefix + '/' + dropdown.Link
                            : dropdown.Link
                        }
                        target={`${dropdown.NewTab == true ? '_blank' : ''}`}
                        className={`px-0 px-lg-2 my-1 my-lg-0`}
                        onClick={() => setExpanded(false)}
                      >
                        {dropdown.Title}
                      </Dropdown.Item>
                      {!!dropdown.Description && (
                        <div className={`d-none d-lg-block pt-1 px-2`}>
                          {dropdown.Description}
                        </div>
                      )}
                    </Col>
                  )
              )}
            </Row>
          </Col>
        </Row>
      </Container>
    </DropdownButton>
  );
};

const LinkItem = ({ item, component, linkprefix }) => {
  return (
    <Nav.Link
      href={
        item.NavLink && item.NavLink.indexOf('http') == -1
          ? linkprefix + '/' + item.NavLink + '/'
          : item.NavLink
      }
      target={`${item.NewTab == true ? '_blank' : ''}`}
      className={'py-lg-2' + (component === item.Identity ? ' active' : '')}
    >
      {item.NavText}
    </Nav.Link>
  );
};

const Header = (pageData) => {
  const signUpLink = useSignUpLink();
  const loginLink = useLoginLink();

  const pageKey = Object.keys(pageData);
  const headerdata = pageKey.length >= 1 ? pageData[0] : false;
  const translate = pageKey.length >= 3 ? pageData[2].data.attributes : false;

  const [expanded, setExpanded] = useState(false);
  const [fullscreen, setFullscreen] = useState(true);
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
  };
  const handleShow = (breakpoint) => {
    setFullscreen(breakpoint);
    setShow(true);
  };

  const language = [
    {
      id: 1,
      value: [
        {
          name: '/HK/zh-Hant',
          country: 'HK',
          lang: 'zh-Hant',
          countryText: '香港',
          langText: '繁體中文',
        },
      ],
    },
    {
      id: 2,
      value: [
        {
          name: '/HK/en',
          country: 'HK',
          lang: 'en',
          countryText: 'Hong Kong',
          langText: 'English',
        },
      ],
    },
    // {id: 3, value: [{name: '/US/en', country: 'US', lang: 'en', countryText: 'United States', langText: 'English'}]},
    {
      id: 4,
      value: [
        {
          name: '/MY/en',
          country: 'MY',
          lang: 'en',
          countryText: 'Malaysia',
          langText: 'English',
        },
      ],
    },
    // {id: 5, value: [{name: '/MY/ms', country: 'MY', lang: 'ms', countryText: 'Malaysia', langText: 'Malay Bahasa'}]},
    {
      id: 6,
      value: [
        {
          name: '/MY/zh-Hans',
          country: 'MY',
          lang: 'zh-Hans',
          countryText: '马来西亚',
          langText: '简体中文',
        },
      ],
    },
    // {
    //   id: 7,
    //   value: [
    //     {
    //       name: '/IND/en',
    //       country: 'IND',
    //       lang: 'en',
    //       countryText: 'Indonesia',
    //       langText: 'English',
    //     },
    //   ],
    // },
    // {id: 8, value: [{name: '/IND/ina', country: 'IND', lang: 'ina', countryText: 'Indonesia', langText: 'Indo Bahasa'}]},
    // {id: 9, value: [{name: '/JP/en', country: 'JP', lang: 'en', countryText: 'Japan', langText: 'English'}]},
    // {id: 10, value: [{name: '/JP/ja', country: 'JP', lang: 'ja', countryText: 'Japan', langText: 'Japanese'}]},
    {
      id: 11,
      value: [
        {
          name: '/Others/en',
          country: 'Others',
          lang: 'en',
          countryText: 'Others',
          langText: 'English',
        },
      ],
    },
  ];

  const NavbarLink = ({ item }) => {
    return (
      <>
        {!item.Hidden &&
          (item.Dropdown?.length > 0 ? (
            <DropdownLinkItem
              item={item}
              component={component}
              linkprefix={linkprefix}
              setExpanded={setExpanded}
            />
          ) : (
            <LinkItem
              item={item}
              component={component}
              linkprefix={linkprefix}
            />
          ))}
      </>
    );
  };

  const getUrl = (country, lang) => {
    if (typeof window !== 'undefined') {
      const pathname = window.location.pathname;
      const tokens = pathname.split('/');
      tokens[1] = country;
      tokens[2] = lang;
      return tokens.join('/');
    } else {
      return '';
    }
  };

  const Head = ({
    country,
    greenLogoLink,
    greenLogoAlt,
    whiteLogoLink,
    whiteLogoAlt,
    menu,
  }) => {
    return (
      <header className={component === 'homepage' ? 'landing' : ''}>
        <Navbar fixed={`top`} bg={`white`} expand={`lg`} expanded={expanded}>
          <Container>
            <Navbar.Brand href={`${linkprefix}/`} className={`me-4`}>
              <div className="brand-logo logo-green">
                <LazyLoadImage
                  src={greenLogoLink}
                  alt={greenLogoAlt}
                  effect={`blur`}
                  width={`100`}
                  height={`41`}
                />
              </div>
              <div className="brand-logo logo-white">
                <LazyLoadImage
                  src={whiteLogoLink}
                  alt={whiteLogoAlt}
                  effect={`blur`}
                  width={`100`}
                  height={`41`}
                />
              </div>
            </Navbar.Brand>
            <div className={`d-flex align-items-center`}>
              <a
                className={`btn btn-green me-1 py-2 px-3`}
                href={`http://boutir.com/app`}
                target={`_blank`}
                data-section="header"
              >
                {translate.TryForFree}
              </a>
              <Navbar.Toggle
                aria-controls={`navbarToggler`}
                onClick={() => {
                  setExpanded(expanded ? false : 'expanded');
                }}
              >
                <span className={`toggler-icon top-bar`}></span>
                <span className={`toggler-icon middle-bar`}></span>
                <span className={`toggler-icon bottom-bar`}></span>
              </Navbar.Toggle>
            </div>
            <Navbar.Collapse
              id={`navbarToggler`}
              className={`flex-column flex-lg-row align-items-start justify-content-between`}
            >
              <Nav
                className={`me-auto mb-2 mb-lg-0 pt-3 pt-lg-0 align-items-start align-items-lg-center`}
              >
                {menu?.map((item) => (
                  <NavbarLink key={item.id} item={item} />
                ))}
              </Nav>
              <form
                className={`d-flex flex-column flex-lg-row align-items-center pt-4 pt-lg-0`}
              >
                <a
                  className={`btn btn-outline me-0 me-lg-2 py-2 px-3 col-12 col-lg-auto order-2 order-lg-1`}
                  href={loginLink}
                >
                  {translate.MerchantLogin}
                </a>
                <a
                  className={`btn btn-green py-2 px-3 col-lg-auto order-lg-2`}
                  href={signUpLink}
                  data-section="header"
                >
                  {translate.TryForFree}
                </a>
                <Button
                  variant={`link`}
                  className={`language-switcher d-flex align-items-center ms-0 ms-lg-3 mb-2 mb-lg-0 py-3 py-lg-1 px-3 col-12 col-lg-auto order-1 order-lg-3`}
                  onClick={() => handleShow('lg-down')}
                >
                  <span className={`d-lg-none ms-3`}>
                    {curlang.value[0].countryText} |{' '}
                    {`${curlang.value[0].langText}`}
                  </span>
                </Button>
              </form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <Modal
          show={show}
          fullscreen={fullscreen}
          onHide={handleClose}
          centered
        >
          <Modal.Header className={`justify-content-center`} closeButton>
            <Modal.Title>{translate.SelectLanguage}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Row>
              {language.map((item, index) => (
                <Col key={`${index}`} xs={6}>
                  {item.value.map((val, index) => (
                    <a
                      key={`${index}`}
                      className={
                        `btn btn-outline w-100 text-start py-3 px-3 mb-4` +
                        (linkprefix === val.name ? ' active' : '')
                      }
                      href={getUrl(val.country, val.lang)}
                      onClick={() => {
                        setCookies(val.country, val.lang);
                        setShow(false);
                        setExpanded(false);
                      }}
                    >
                      <div>{val.langText}</div>
                      <div>{val.countryText}</div>
                    </a>
                  ))}
                </Col>
              ))}
            </Row>
          </Modal.Body>
        </Modal>
      </header>
    );
  };

  const router = useRouter();
  const { asPath } = router;
  let component =
    asPath.split('/').length > 4 ? asPath.split('/')[3] : 'homepage';

  let { country, lang } = getGlobal().linkstate;
  let linkprefix = `/${country}/${lang}`;

  let curlang = language[0];
  let curlangkey = language.findIndex(
    (ele) => ele.value[0].lang === lang && ele.value[0].country === country
  );
  if (curlangkey !== -1) {
    curlang = language[curlangkey];
  }

  let mData = '';
  if (headerdata.data !== undefined) {
    mData = headerdata.data.attributes;
  }

  // let translate = getGlobal().translation;

  if (!mData) {
    return null;
  }

  return (
    <Head
      country={country}
      lang={lang}
      greenLogoLink={mData.GreenLogoLink}
      greenLogoAlt={mData.GreenLogoAlt}
      whiteLogoLink={mData.WhiteLogoLink}
      whiteLogoAlt={mData.WhiteLogoAlt}
      menu={mData.Menu}
    />
  );
};

export default Header;
