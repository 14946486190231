import { useCallback } from 'reactn';

export default function useWebview() {
  const isAndroidWebview = useCallback(
    () =>
      typeof window !== 'undefined' &&
      window.navigator.userAgent === 'android_app',
    []
  );

  const isIOSWebview = useCallback(
    () =>
      typeof window !== 'undefined' &&
      window.navigator.userAgent === 'Boutir_ios_subscription',
    []
  );

  const isWebview = useCallback(() => isAndroidWebview() || isIOSWebview(), [
    isAndroidWebview,
    isIOSWebview,
  ]);

  return {
    isAndroidWebview,
    isIOSWebview,
    isWebview,
  };
}
