import { getGlobal } from "reactn";
import { useRouter } from 'next/router';

const languages = {
  en: 'en',
  'zh-Hant': 'zh-HK',
  'zh-Hans': 'zh-CN',
  ms: 'ms',
  ina: 'id',
  ja: 'ja',
};

export const useSignUpLink = () => {
  const { boutirAdminUrl } = useBoutirAdmin();
  const { country } = getGlobal().linkstate;

  return `${boutirAdminUrl}/signUp?country=${country}`;
}

export const useLoginLink = () => {
  const { boutirAdminUrl } = useBoutirAdmin();
  const { country } = getGlobal().linkstate;

  return `${boutirAdminUrl}/login?country=${country}`;
}

function useBoutirAdmin() {
  const router = useRouter();
  const locale = languages[router.query.lang] ?? 'en';
  const boutirAdminUrl = `/business/${locale}`;
  return { boutirAdminUrl };
}
