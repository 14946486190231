import React, { useEffect, useState } from "reactn";

import SsrSEO from '../components/SsrSEO'
import Header from '../components/header';
import Footer from '../components/footer';
import useWebview from '../hooks/useWebview';

const PageWrapper = ({children, pageData}) => {
  const [showsNavigation, setShowsNavigation] = useState(true);
  const { isWebview } = useWebview();

  // check inside useEffect to avoid server/client mismatch
  useEffect(() => {
    if (isWebview()) {
      setShowsNavigation(false);
    }
  }, [setShowsNavigation, isWebview]);

  return (
    <>
      <SsrSEO {...pageData} />
      {showsNavigation && <Header {...pageData} />}
      {children}
      {showsNavigation && <Footer {...pageData} />}
    </>
  )
}

export default PageWrapper;
